class MobileMenu {
	constructor() {
		this.menuIcon = document.querySelector('.site-header__menu-icon');
		this.menuContent = document.querySelector('.primary-nav');
		this.siteHeader = document.querySelector('.site-header');
		this.htmlBody = document.querySelector('body');
		this.events();
	}

	events() {
		this.menuIcon.addEventListener('click', () => this.toggleTheMenu());
	}

	toggleTheMenu() {
		this.menuContent.classList.toggle('primary-nav--is-visible');
		this.siteHeader.classList.toggle('site-header--is-expanded');
		this.menuIcon.classList.toggle('site-header__menu-icon--close-x');
		this.htmlBody.classList.toggle('noscroll');
	}
}

export default MobileMenu;
