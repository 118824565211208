const expanded_main_menu = document.querySelector('.site-header__menu-icon'); //mobile parent menu screen
const menu = document.querySelector('.menu'); // main menu parent
const menu_items = document.querySelectorAll('.menu-item-has-children'); // li children of menu

/* Activate Submenu */
function toggleItem() {
	if (this.classList.contains('expanded')) {
		this.classList.remove('expanded');
	} else if (menu.querySelector('.expanded')) {
		menu.querySelector('.expanded').classList.remove('expanded');
		this.classList.add('expanded');
	} else {
		this.classList.add('expanded');
	}
}

/* Event Listeners */
expanded_main_menu.addEventListener('click', function () {
	for (const submenu of menu_items) {
		if (submenu.querySelector('.sub-menu')) {
			submenu.addEventListener('click', toggleItem, false);
		}
		submenu.addEventListener('keypress', toggleItem, false);
	}
});
