import '../css/styles.css';

/* Main Website JS */
// Modules
import mobileMenu from './modules/MobileMenu';
import stickyHeader from './modules/StickyHeader';
import expanderMenu from './modules/ExpanderMenu';

// Global Functionality
new stickyHeader();
const MobileMenu = new mobileMenu();

//to add jquery to any page etc
//this looks for a class name in the body tag to see if it should load jQuery
/* const use_jQuery = document.querySelector('.use_jQuery');
if (use_jQuery !== null) {} */
//import 'jquery';

// back-to-top
// Set a variable for our button element.
const ToTopButton = document.getElementById('js-top');

// Let's set up a function that shows our scroll-to-top button if we scroll beyond the height of the initial window.
const scrollFunc = () => {
	// Get the current scroll value
	const windowheight = window.scrollY;

	// If the scroll value is greater than the window height, let's add a class to the scroll-to-top button to show it!
	if (windowheight > 500) {
		ToTopButton.className = 'back_to_top show';
	} else {
		ToTopButton.className = 'back_to_top hide';
	}
};

window.addEventListener('scroll', scrollFunc);

// When the button is clicked, run our ScrolltoTop function above!
ToTopButton.onclick = function (e) {
	e.preventDefault();
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
};

/* Ballistic Arts Javascript */
function openMenu() {
	jQuery('#mainMenu').toggleClass('open'), jQuery('body').toggleClass('noscroll'), jQuery('header').toggleClass('bg-white');
	jQuery('#menuBtnIcon').toggleClass('fa-close');
	jQuery('#menuBtnIcon').toggleClass('fa-bars')
}
jQuery(() => {
	jQuery('#searchButton').on('click', () => {
		console.log(jQuery('#s').width(), jQuery('#s').val()), 146 !== jQuery('#s').width() || '' === jQuery('#s').val() ? jQuery('#s').trigger('focus') : jQuery('#searchform').trigger('submit')
	}), jQuery('#s').on('click', e => {
		e.stopPropagation()
	}), 'competentboards.yourballistic.com' === window.location.hostname && jQuery('img').each((function (e) {
		jQuery(this).attr('src', jQuery(this).attr('src').replace('http://localhost/ESG', ''))
	}))
});

setTimeout(function () {
	document.body.classList.remove('preload');
}, 500);

let cohortPicker = document.getElementById('cohortPicker');
let timePicker = document.getElementById('timePicker')

let showAllSessions = false;

/* Loader */
var Loader = {
	loader: null,
	body: null,
	html: '<span><svg width="40" height="40" version="1.1" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="15"></svg></span>',
	cssClass: 'loader',
	check: function () {
		if (this.body == null) {
			this.body = document.getElementsByTagName('body')[0];
		}
	},
	open: function () {
		this.check();
		if (!this.isOpen()) {
			this.loader = document.createElement('div');
			this.loader.setAttribute('id', 'loader');
			this.loader.classList.add('loader_website');
			this.loader.innerHTML = this.html;
			this.body.append(this.loader);
			this.body.classList.add(this.cssClass);
		}
		return this;
	},
	close: function () {
		this.check();
		if (this.isOpen()) {
			this.body.classList.remove(this.cssClass);
			this.loader.remove();
		}
		return this;
	},
	isOpen: function () {
		this.check();
		return this.body.classList.contains(this.cssClass);
	},
	ifOpened: function (callback, close) {
		this.check();
		if (this.isOpen()) {
			if (!!close)
				this.close();
			if (typeof callback === 'function') {
				callback();
			}
		}
		return this;
	},
	ifClosed: function (callback, open) {
		this.check();
		if (!this.isOpen()) {
			if (!!open)
				this.open();
			if (typeof callback === 'function') {
				callback();
			}
		}
		return this;
	}
};
/* Loader */

if (cohortPicker && timePicker) {
	function showCohort() {
		if (cohortPicker.value == 'choose') {
			jQuery('.bm').show();
			jQuery('.bp').show();
			jQuery('.initialNeutral').show()
		} else if (cohortPicker.value == 'bm') {
			jQuery('.bm').show();
		} else if (cohortPicker.value == 'bp') {
			jQuery('.bp').show();
		}
	}

	function hideCohort() {
		if (cohortPicker.value == 'choose') {
			jQuery('.startHidden').hide();
		} else if (cohortPicker.value == 'bm') {
			jQuery('.bp').hide();
			jQuery('.initialNeutral').hide()
		} else if (cohortPicker.value == 'bp') {
			jQuery('.bm').hide();
			jQuery('.initialNeutral').hide()
		}
	}

	function changeItems() {
		Loader.open()
		setTimeout(() => {
			Loader.close();
		}, 500)
		if (timePicker.value == 'SepMar2021') {
			showCohort()
			if (cohortPicker.value != 'choose') {
				jQuery('.SepMar2021').show();
				jQuery('.sessionTable').hide();
			} else {
				jQuery('.SepMar2021').hide();
				jQuery('.sessionTable').show();
			}
			jQuery('.OctMar2021').hide();
			jQuery('.MayNov2022').hide();
			jQuery('.FebJul2022').hide();
			hideCohort()
		} else if (timePicker.value == 'FebJul2022') {
			showCohort()
			jQuery('.SepMar2021').hide();
			jQuery('.OctMar2021').hide();
			if (cohortPicker.value != 'choose') {
				jQuery('.FebJul2022').show();
				jQuery('.sessionTable').hide();
			} else {
				jQuery('.FebJul2022').hide();
				jQuery('.sessionTable').show();
			}
			jQuery('.MayNov2022').hide();
			hideCohort()
		} else if (timePicker.value == 'OctMar2021') {
			showCohort()
			jQuery('.SepMar2021').hide();
			jQuery('.FebJul2022').hide();
			if (cohortPicker.value != 'choose') {
				jQuery('.OctMar2021').show();
				jQuery('.sessionTable').hide();
			} else {
				jQuery('.OctMar2021').hide();
				jQuery('.sessionTable').show();
			}
			jQuery('.MayNov2022').hide();
			hideCohort()
		} else if (timePicker.value == 'MayNov2022') {
			showCohort()
			jQuery('.SepMar2021').hide();
			jQuery('.FebJul2022').hide();
			jQuery('.OctMar2021').hide();
			if (cohortPicker.value != 'choose') {
				jQuery('.MayNov2022').show();
				jQuery('.sessionTable').hide();
			} else {
				jQuery('.MayNov2022').hide();
				jQuery('.sessionTable').show();
			}
			hideCohort()
		} else if (timePicker.value == 'tba') {
			showCohort()
			jQuery('.SepMar2021').hide();
			jQuery('.OctMar2021').hide();
			jQuery('.MayNov2022').hide();
			jQuery('.FebJul2022').hide();
			jQuery('.sessionTable').show();
			jQuery('.tba').show();
			hideCohort()
		} else {
			showCohort()
			jQuery('.SepMar2021').hide();
			jQuery('.OctMar2021').hide();
			jQuery('.MayNov2022').hide();
			jQuery('.FebJul2022').hide();
			hideCohort()
		}

		if (!showAllSessions) {
			jQuery(`.${cohortPicker.value}.${timePicker.value}`).each(function (index) {
				if (index >= 6) {
					jQuery(this).hide();
					jQuery('#loadMoreSessions').show();
				} else {
					jQuery('#loadMoreSessions').hide();
				}
			});
		}
	}

	jQuery('#loadMoreSessions').on('click', () => {
		showAllSessions = true;
		jQuery('#loadMoreSessions').hide();
		changeItems();
	});

	cohortPicker.addEventListener('change', function () {
		localStorage.setItem('cohort', cohortPicker.value);

		adjustSelectBox();
		changeItems()
	});

	timePicker.addEventListener('change', function () {
		localStorage.setItem('sessionTime', timePicker.value);
		changeItems()
	});
}

function adjustSelectBox() {

	if (cohortPicker.value == 'bp' && window.location.pathname === '/esg-certificate-program/') {
		if (timePicker.value == 'OctMar2021') timePicker.value = 'MayNov2022';
		jQuery('option[value="OctMar2021 "]').hide();
	} else {
		jQuery('option[value="OctMar2021 "]').show();
	}
}

document.addEventListener('DOMContentLoaded', function () {
	if (localStorage.getItem('cohort')) {
		if (cohortPicker) cohortPicker.value = localStorage.getItem('cohort');
		jQuery('.chooseCohortBox').hide();
	} else {
		if (window.location.pathname === '/climate-competent-boards/' || window.location.pathname === '/esg-certificate-program/') {
			jQuery('.chooseCohortBox').css('display', 'flex');
		}
	}
	/*if(localStorage.getItem('sessionTime')) {
	  if(timePicker) timePicker.value = localStorage.getItem('sessionTime');
  }*/
	if (timePicker && cohortPicker) {
		adjustSelectBox();
		changeItems();
	}
});

if (document.getElementById('switchMayNov')) {
	document.getElementById('switchMayNov').addEventListener('click', function () {
		timePicker.value = 'MayNov2022';
		localStorage.setItem('sessionTime', 'MayNov2022');
		changeItems()
	})
}

if (document.getElementById('switchFebJul')) {
	document.getElementById('switchFebJul').addEventListener('click', function () {
		timePicker.value = 'FebJul2022';
		localStorage.setItem('sessionTime', 'FebJul2022');
		changeItems()
	})
}


var bmEl = document.getElementsByClassName('chooseBM');
for (var i = 0; i < bmEl.length; i++) {
	bmEl[i].addEventListener('click', () => {
		cohortPicker.value = 'bm';
		localStorage.setItem('cohort', 'bm');
		changeItems();
		jQuery('.chooseCohortBox').hide();
		timePicker.value = jQuery('#bmDefault p').text();
	}, false);
}

var bpEl = document.getElementsByClassName('chooseBP');
for (var i = 0; i < bpEl.length; i++) {
	bpEl[i].addEventListener('click', () => {
		cohortPicker.value = 'bp';
		localStorage.setItem('cohort', 'bp');
		changeItems();
		jQuery('.chooseCohortBox').hide();
		timePicker.value = jQuery('#bpDefault p').text();

	}, false);
}

if (document.querySelector('.stickySubmenu')) {
	window.onscroll = function () {
		scrollHandler()
	};

	var header = document.querySelector('.stickySubmenu');
	var sticky = header.offsetTop;

	function scrollHandler() {
		if (window.pageYOffset > sticky) {
			header.classList.add('stuck');
			document.querySelector('.mainBanner').style.marginBottom = '71px';
		} else {
			header.classList.remove('stuck');
			document.querySelector('.mainBanner').style.marginBottom = '0';
		}
	}
}

const showBanner = localStorage.getItem('announcementBar');
if (showBanner === 'false' || true) { // || TRUE HERE TO STOP DISPLAYING IT TEMPORARILY
	var style = document.createElement('style');
	style.innerHTML = '#announcementBar { display: block; }';
	document.getElementsByTagName('head')[0].appendChild(style);
}
localStorage.setItem('announcementBar', 'false');

const close_announcement_bar = document.getElementById('announcementBar');
const main_page_position = document.querySelector('main');

//close_announcement_bar.style.display = 'none';
document.getElementById('closeAnnouncement').addEventListener('click', function () {
	close_announcement_bar.style.display = 'none';
	main_page_position.classList.toggle('show_announcement_bar')
});
